
export enum PaymentSystem {
  visa = 'Visa',
  mastercard = 'MasterCard',
  discover = 'Discover'
}

export enum DataDescriptor {
  payment = "COMMON.ACCEPT.INAPP.PAYMENT"
}

export interface PaymentCard {
  id: string,
  issuerNumber: string,
  cardType: PaymentSystem,
  cardNumber: string,
  expirationDate: string,
  isDefault: boolean
}

export interface ProfilePaymentsResponce {
  id: string,
  description: string,
  email: string,
  paymentProfiles: PaymentCard[]
}

export interface Card {
  cardNumber: string,
  cardCode: string,
  month: string,
  year: string,
}

export interface CardDescriptor {
  dataDescriptor: DataDescriptor;
  dataValue: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  country: string;
  zip: string;
  state: string;
  email: string;
}

export enum BlockchainPlatforms {
  TRC20 = 'TRC20',
  ERC20 = 'ERC20',
  Ton = 'Ton'
}
